import React from "react";
import { PageWrapper } from "~components/Core";
import HeroSection from '~sections/course-1/Hero'
import ServicesSection from '~sections/course-1/Services'
import AboutSection from '~sections/about/about'
import ProcessSection from '~sections/course-1/Process'
import PricingSection from '~sections/course-1/Pricing'
// import TestimonialSection from '~sections/course-1/Testimonial'
import CtaSection from '~sections/contact/ContactOne/ContactSection'
import HeaderButton from '~sections/course-1/Header'
import FooterFive from '~sections/course-1/FooterFive'


const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid:false,
  darkLogo:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnText="Записаться"
      mr="15px"
      mrLG="0"
    />
  ),
}

export default function Services() {
  return (
    <PageWrapper headerConfig={header}>
        <HeroSection/>
        <ServicesSection/>
        <ProcessSection/>
        <AboutSection/>
        <PricingSection/>
        {/* <TestimonialSection/> */}
        <CtaSection/>
        <FooterFive/>
    </PageWrapper>
  )
}


const ServiceData = [

        {
          id:"as1",
          icon: "fab fa-js-square",
          iconColor: "#F7DF1E",
          title: "Программированию на Javascript",
          text:
            "Изучите основы языка и узнаете что такое модули, функциональное программирование и ООП.",
        },
        {
          id:"as2",
          icon: "fab fa-html5",
          iconColor:"#DD4B25",
          title: "Качественной верстке сайтов",
          text:
            "Изучите как создавать структуру сайта с помощью HTML тегами.",
        },
        {
          id:"as3",
          icon: "fab fa-css3-alt",
          iconColor:"#254BDD",
          title: "CSS",
          text:
            "Узнаете, как создавать дизайн сайтов с помощью CSS для всех актуальных версий браузеров и платформ.",
        },
        {
          id:"as4",
          icon: "fab fa-git",
          iconColor:"#F05033",
          title: "Систему контроля версий",
          text:
            "Изучите работу с локальным и удаленным хранилищем, а также изучите основные концепции Git.",
        },
        {
          id:"as5",
          icon: "fas fa-server",
          iconColor:"#6001d3",
          title: "Работать с API",
          text:
            "Научитесь отправлять и получать запросы на сервер и изучите технологию Postman.",
        },
  
      ]
  export default ServiceData;